<div class="modalWrap termsModal">
    <div>
        <h2>New here ?</h2>
    </div>
    <div class="content">
        <p>
            Enjoy a first use discount for a new selection of prime advisors.  
        </p>
        <br>
        <p>
            Spot the discount icon <img src="../../../../../assets/discount-icon.svg" height="" width="" alt="dp"
            class="badgeIcon" />
        </p>
    </div>
    <div class="btn-group text-center">
        <!-- <button type="button" class="button heightBtn whiteBtn" (click)="activeModal.close('cancel')">Cancel</button> -->
        <button type="button" class="button heightBtn greenBtn" (click)="activeModal.close('true')">Got It</button>
    </div>
</div>
<button type="button" class="close" aria-label="Close" (click)="activeModal.close('cancel')">
    <img src="../../../../../assets/sign-exit-grey.svg" height="" width="" alt="sign-exit">
</button>