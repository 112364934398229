/**
 * @Author Adnan
 * @since 1-8-21
 * @Description API endpoints for backend
 *
 **/
import { environment } from 'src/environments/environment';

let baseUrl = environment.baseUrl;

export class URLConfig {
  //Save payment method
  public static paymentCard = baseUrl + 'api/user/payment_card/';

  //Check user existance
  public static userExist = baseUrl + 'api/user/check_user';

  //Auth Urls
  public static login = baseUrl + 'api/user/login';
  public static signup = baseUrl + 'api/user/signup';
  public static refreshToken = baseUrl + 'api/user/refresh-token';
  public static verifyToken = baseUrl + 'api/user/verify-token';
  public static allExperties = baseUrl + 'api/advisor/all_categories';
  public static allSubExperties = baseUrl + 'api/advisor/all_sub_categories';
  public static allHeadlines = baseUrl + 'api/advisor/all_headlines';
  public static confirmEmailForgotPassword =
    baseUrl + 'api/user/password_reset/';
  public static resetPassword = baseUrl + 'api/user/password_reset/confirm/';
  public static verifyEmail = baseUrl + 'api/user/verify_email';
  public static resendVerifyEmail = baseUrl + 'api/user/resend_verify_email';
  public static logout = baseUrl + 'api/user/accounts/logout/';

  //Advisor Signup
  public static allLanguages = baseUrl + 'api/advisor/all_languages';
  public static allLocations = baseUrl + 'api/advisor/all_locations';

  // Account Settings
  public static profile = baseUrl + 'api/user/profile';
  public static updateAttribute = baseUrl + 'api/user/user_attributes';
  public static changePassword = baseUrl + 'api/user/change_password';
  public static deleteAccount = baseUrl + 'api/user/delete_account';
  public static advisorCategories = baseUrl + 'api/advisor/advisor_categories';
  public static advisorCategoriesV1 = baseUrl + 'api/advisor/v1/advisor_categories';
  public static advisorPackages = baseUrl + 'api/advisor/packages';
  public static advisorAvailability =
    baseUrl + 'api/advisor/advisor_availability';
  public static updatePackage = baseUrl + 'api/advisor/update_package';
  public static newCategory = baseUrl + 'api/advisor/create_package';
  public static deleteCategory = baseUrl + 'api/advisor/delete_category';

  public static resetLastSeen = baseUrl + 'api/advisor/reset_last_seen';

  //Social Authentication
  public static loginWithGoogle = baseUrl + 'api/user/auth/google/';
  public static loginWithFacebook = baseUrl + 'api/user/auth/facebook/';

  //Discover Advisor
  public static advisorExamples = baseUrl + 'api/example/advisor/examples/';
  public static catgeorizedExamples =
    baseUrl + 'api/example/category/examples/';
  public static discoverAdvisors = baseUrl + 'api/advisor/discover';
  public static favouriteAdvisor = baseUrl + 'api/advisor/favourite_advisor';
  public static advisorDiscounts = baseUrl + 'api/user/advisor_discounts';
  public static fetchUserPrograms = baseUrl + 'api/advisor/user_programs/';

  //Advisor Available/Unavailable Status
  public static advisorAvailableStatus =
    baseUrl + 'api/advisor/advisor_available_toggle';

  //Submit Resume
  public static submitResume = baseUrl + 'api/user/submit_resume';
  public static undoSubmitResume = baseUrl + 'api/user/undo_submit_resume';

  //Upload Work
  public static uploadImage = baseUrl + 'api/work/snapshots';
  public static uploadWork = baseUrl + 'api/work/upload_work/';

  //Upload Work
  public static validateCoupon = baseUrl + 'api/work/coupon/validation/';

  //User Dashboard
  public static userWorks = baseUrl + 'api/work/user/';
  public static advisorWorks = baseUrl + 'api/work/advisor/';
  public static dashboardDetails = baseUrl + 'api/work/work_details/';
  public static reviewedWorksDetail =
    baseUrl + 'api/work/reviewed_work_details/';
  public static markWorkSummaryAsViewed =
    baseUrl + 'api/work/mark_works_as_viewed/';

  //Annotation
  public static annotationBase = baseUrl + 'api/work/work_annotation/';

  //Request user for sharing work
  public static advisorExampleRequest =
    baseUrl + 'api/user/advisor_example_request/';

  //Rate advisor
  public static advisorRating = baseUrl + 'api/user/advisor_rating/';

  //Examples and Portfolio
  public static examples = baseUrl + 'api/example';
  public static homeExamples = baseUrl + 'api/example/latest';
  public static exampleLike = baseUrl + 'api/work/work_summary/like/';
  public static addWorkToPortfolio =
    baseUrl + 'api/example/add_work_to_portfolio';
  public static checkExample =
    baseUrl + 'api/example/check_work_shared_already';
  public static userShareWork = baseUrl + 'api/example/user_share_work';

  //Notifications list
  public static notifications = baseUrl + 'api/notifications';
  public static unreadNotificationCount =
    baseUrl + 'api/notifications/unread_notification_count';
  public static markAllAsRead = baseUrl + 'api/notifications/mark_all_as_read';
  public static markNotificationAsRead =
    baseUrl + 'api/notifications/mark_notification_as_read/';
  public static getMyWorkNumberNotification =
    baseUrl + 'api/notifications/get_my_work_number_notifications/';

  //Messages
  public static conversation = baseUrl + 'api/communication/conversation/';
  public static messages = baseUrl + 'api/communication/get_messages';
  public static sendMessage = baseUrl + 'api/communication/send_message/';
  public static markMessagesAsRead =
    baseUrl + 'api/communication/mark_messages_as_read/';
  public static unreadConversationsCount =
    baseUrl + 'api/communication/conversation/unread_conversations_count';

  //Advisor education & experience update
  public static advisorEducationExp =
    baseUrl + 'api/user/advisor_education_experience/';

  //Advisor transactions
  public static advisorBalanceTransaction =
    baseUrl + 'api/payments/advisor/transaction/';
  public static advisorOrderTransaction =
    baseUrl + 'api/payments/advisor/order/transaction/';
  public static userOrderTransaction =
    baseUrl + 'api/payments/user/order/transaction/';

  // Interactive Package Notifications
  public static commentNotification =
    baseUrl + 'api/work/comments_notification';

  public static convertFile = baseUrl + 'api/work/convert-file/';

  public static logoutApi = baseUrl + '/api/user/user_type';

  public static uploadWorkImage = baseUrl + 'api/advisor/upload_image';
}
