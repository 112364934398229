<div class="btnWrap">
  <button (click)="signInWithGoogle()">
    <img
      src="../../../../../assets/google-icon.svg"
      height=""
      width=""
      alt="google"
    />
    {{ actionType }} with Google
  </button>
  <!-- <button (click)="signInWithLinkedin()">
    <i class="fa fa-linkedin"></i> <span> Log in with Linkedin</span>
  </button> -->
  <!-- <button (click)="signInWithFB()">
    <i class="fa fa-facebook-f"></i> <span>{{ actionType }} with Facebook</span>
  </button> -->
</div>
