<ng-container *ngIf="advisors.length > 0; else advisorsNotFound">
  <div class="dicoverAdFlex">
    <ng-container *ngFor="let advisor of advisors">
      <button (click)="visitProfileinMobile(advisor)" class="parent-btn"
        [style.cursor]="windowWidth <= 766 ? 'pointer' : 'default'">
        <div class="discoverAdBox" [class.selected]="advisor.isSelected">
          <div class="discoverAdBoxTop">
            <div>
              <div class="dpWrap dpWrapTwo">
                <app-profile-picture [userDetails]="advisor"></app-profile-picture>
                <img src="../../../../assets/advisor-badge.svg" height="" width="" alt="dp" class="badgeIcon" />
              </div>
              <div>
                <p class="expLabel" *ngIf="
                    advisor.advisor_level != userTypes.NEW &&
                    advisor.advisor_level != userTypes.NORMAL
                  ">
                  <ng-container *ngIf="
                      !advisor.isSelected &&
                      advisor?.advisor_level != userTypes.NEW &&
                      advisor.advisor_level != userTypes.NORMAL
                    ">
                    <img *ngIf="
                        advisor?.advisor_level == userTypes.EXPERIENCED ||
                        advisor?.advisor_level == 'EXPERIENCED_ADVISOR'
                      " src="../../../../assets/experienced.svg" height="" width="" alt="dp" class="badgeIcon" />
                    <img *ngIf="
                        advisor?.advisor_level == userTypes.PRIME ||
                        advisor?.advisor_level == 'PRIME_ADVISOR'
                      " src="../../../../assets/prime.svg" height="" width="" alt="dp" class="badgeIcon" />
                  </ng-container>
                  <span *ngIf="!advisor.isSelected">{{
                    advisor.advisor_level == "EXPERIENCED_ADVISOR"
                    ? userTypes.EXPERIENCED
                    : advisor.advisor_level
                    }}</span>
                  <span *ngIf="advisor.isSelected">Selected</span>
                </p>
                <div class="adOffWrap" *ngIf="
                    showPriceTags &&
                    this.selectedAdvisorsAndPackages?.length > 0 &&
                    !isSelected(advisor?.id) &&
                    advisor?.new_advisor_discount &&
                    (advisor?.advisor_level == userTypes.NEW ||
                      advisor?.advisor_level == userTypes.NORMAL ||
                      advisor?.advisor_level == userTypes.EXPERIENCED)
                  ">
                  <p>
                    -25%
                    <img src="../../../../assets/experiencedDiscount.svg" height="" width="" alt="dp" />
                    <!-- <img src="../../../../assets/2+.svg" height="" width="" alt="dp" /> -->
                  </p>
                  <div class="infoTooltip adviceTooltip">
                    <ng-template #htmlContentt>
                      <div>
                        <img src="../../../../assets/green-info.svg" height="" width="" alt="star" />
                        <div style="display: flex; flex-direction: column">
                          <h6>Second Advice Coupon</h6>
                          <p>
                            Don't settle for one advisor. Use our "25% second
                            advice discount" to get a broad perspective from
                            several advisors at once
                          </p>
                        </div>
                      </div>
                    </ng-template>
                    <button class="transBtn" placement="top" [ngbTooltip]="htmlContentt">
                      <img src="../../../../assets/green-info.svg" height="" width="" alt="star" />
                    </button>
                  </div>
                </div>
                <div class="adOffWrap" *ngIf="
                    authenticated &&
                    advisor?.prime_advisor_discount &&
                    userDetails?.total_advice_as_normal_user == 0 &&
                    (advisor?.advisor_level == 'PRIME_ADVISOR' ||
                      advisor?.advisor_level == userTypes.PRIME)
                  ">
                  <p>
                    -20%
                    <img src="../../../../assets/primeDiscount.svg" height="" width="" alt="dp" />
                  </p>
                  <div class="infoTooltip adviceTooltip">
                    <ng-template #htmlContent>
                      <div>
                        <img src="../../../../assets/green-info.svg" height="" width="" alt="star" />
                        <div style="display: flex; flex-direction: column">
                          <h6>Primary Advisor Discount</h6>
                          <p>
                            Get a 20% discount on your first work from this
                            advisor.
                          </p>
                        </div>
                      </div>
                    </ng-template>
                    <button class="transBtn" placement="top" [ngbTooltip]="htmlContent">
                      <img src="../../../../assets/green-info.svg" height="" width="" alt="star" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <input type="checkbox" [checked]="advisor?.isFavourite" (click)="markAsFavourite(advisor)"
              class="favCheck" />
          </div>
          <h5>{{ advisor.full_name }}</h5>
          <h4>
            <img *ngIf="advisor.program_logo" src="{{ advisor.program_logo }}" height="24" width="24"
              alt="advisor-vector" />
            {{ advisor.job_title_experience.job_title }}
          </h4>
          <div class="rating viewRMain">
            <span style="color: #002642; font-weight: 600">{{ advisor.rating || 0 | number : "1.1-1" }} ({{
              advisor.rated_users_count || 0
              }})</span>
            <bar-rating [readOnly]="already_reviewed" class="ratingInput viewRatingMain" [(rate)]="advisor.rating"
              [max]="5">
            </bar-rating>
          </div>
          <div class="advisorFigures">
            <div class="advisor-info">
              <h6>Experience</h6>
              <h6>
                <span>{{
                  advisor.job_title_experience.years_of_experience
                  }}</span>
                years
              </h6>
            </div>
            <div class="advisor-info">
              <h6>Examples</h6>
              <h6>
                <span>{{ advisor.total_example || 0 }}</span>
              </h6>
            </div>
            <div class="advisor-info">
              <h6>Advice</h6>
              <h6>
                <span>{{ advisor.total_advice || 0 }}</span>
              </h6>
            </div>
          </div>
          <button class="button shortBtn greenBtn" (click)="visitProfile(advisor)">
            {{ advisor.isSelected ? "Added" : buttonText }}
          </button>
        </div>
      </button>
    </ng-container>
  </div>
</ng-container>

<ng-template #advisorsNotFound>
  <p style="text-align: center;">No Advisors Found</p>
</ng-template>